<template>
    <div class="mid-active">
        <!--头部-->
        <!-- <nav-bar title="中秋活动" url="/" :border="false" :leftArrow="true"></nav-bar> -->
        <div class="mid-active-main">
            <div class="mid-active-item">
                <img
                    class="mid-active-bg"
                    src="@/assets/images/mid-active1.jpg"
                    alt=""
                />
            </div>
            <div class="mid-active-item">
                <img
                    class="mid-active-bg"
                    src="@/assets/images/mid-active2.jpg"
                    alt=""
                />
                <div class="content">
                    <div class="from-table">
                        <input
                            type="text"
                            placeholder="请输入您的员工工号"
                            v-model="job_number"
                        />
                        <input
                            type="password"
                            placeholder="请输入密码"
                            v-model="password"
                        />
                    </div>
                    <div class="submit-btn">
                        <button @click="submitBtn()">提交</button>
                    </div>
                    <div class="tips">
                        <h2>领取礼品细则：</h2>
                        <p>1. 请您在9月14日前领取完毕；</p>
                        <p><span class="space-line"></span>(过期不能补领)</p>
                        <p>2. 每人只限领取一份；</p>
                        <p>3. 领取礼品不可退换货；</p>
                        <p>4. 如有任何疑问，请联系礼品平台部客服</p>
                        <p><span class="space-line"></span>张旻曦<span class="space-line"></span>020-85720308</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'mid-active',
  data () {
    return {
      // 工号
      job_number: '',
      // 密码
      password: ''
    }
  },
  methods: {
    // 提交
    submitBtn () {
      const that = this
      if (!that.job_number) {
        that.$toast({
          message: '请输入员工工号',
          duration: 2000
        })
        return false
      } else if (!that.password) {
        that.$toast({
          message: '请输入密码',
          duration: 2000
        })
        return false
      }
      that.$axios.post(`/v1/activities/personnel?token=${sessionStorage.token}`, {
        username: that.job_number,
        password: that.password
      }).then((res) => {
        let data = res.data.data
        if (data.code === 1000) {
          // 存储
          sessionStorage.guid = data.data.booth_id
          // token
          sessionStorage.token = data.data.token
          // 成功
          that.$router.push({
            path: '/goodsList',
            query: {
              title: '中秋好礼'
            }
          })
        } else {
          that.$toast({
            message: data.message ? data.message : '操作失败',
            duration: 2000
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.mid-active {
    // padding-top: 1rem;
    &-bg {
        display: block;
        width: 100%;
    }
    &-main {
        .mid-active-item {
            position: relative;
        }
        .content {
            padding: 0 0.6rem;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            .from-table {
                padding: 0 0.32rem;
                input {
                    display: block;
                    width: 100%;
                    margin: 0 auto 0.4rem;
                    padding: 0 0.2rem;
                    height: 0.92rem;
                    background-color: #ffffff;
                    box-sizing: border-box;
                }
            }
            .submit-btn {
                padding: 0 0.32rem;
                text-align: center;
                button {
                    display: inline-block;
                    width: 100%;
                    height: 0.96rem;
                    line-height: 0.96rem;
                    color: #fff;
                    text-align: center;
                    font-size: 0.3rem;
                    border-radius: 0.04rem;
                    background-color: #f38219;
                }
            }
            .tips{
                margin-top: 0.32rem;
                color: #ffffff;
                h2{
                    line-height: 0.56rem;
                    font-size: 0.36rem;
                    font-weight: 500;
                }
                p{
                    line-height: 0.44rem;
                    font-size: 0.3rem;
                    font-weight: normal;
                    .space-line{
                        display: inline-block;
                        width: 0.32rem;
                    }
                }
            }
        }
    }
}
</style>
